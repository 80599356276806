// 定义变量
const advertList = [
  {
    id: '1', title: '充电页弹窗广告', url: require('../assets/homeitemM.jpg'),
    tips: '弹窗：根据推广目标展示不同，例如活动/应用/商品推广',
    txtList: [
      { title: '广告位置', describe: ['广告位在公众号/小程序页面内，由流量主决定实际展示位置，流量场景丰富、多样'] },
      { title: '素材规格', describe: ['图片式 尺寸：800*800 px，不超过100 KB', '图片名片式 尺寸：800*800 px ，不超过100 KB,', '格式：支持 jpg、jpeg、png、bmp'] },
      { title: '弹窗广告支持多种推广目标', describe: ['推广品牌活动、推广商品、推广应用、收集销售线索、推广小游戏、收集销售线索'] },
      { title: '竞价购买：', describe: ['支持提前预定未来 1-28 天内流量，按 CPM 扣费，优先保量'] },
    ]
  },
  {
    id: '2', title: '充电页banner横幅', url: require('../assets/homeitemM.jpg'),
    tips: 'banner：根据推广目标展示不同，例如活动/应用/商品推广',
    txtList: [
      { title: '广告位置', describe: ['广告位在公众号/小程序页面内，由流量主决定实际展示位置，流量场景丰富、多样'] },
      { title: '素材规格', describe: ['图片式 尺寸：800*800 px，不超过100 KB', '图片名片式 尺寸：800*800 px ，不超过100 KB', '格式：支持 jpg、jpeg、png、bmp'] },
      { title: 'Banner广告支持多种推广目标', describe: ['充电成功页列表 广告支持多种推广目标：推广品牌活动、推广商品、推广应用、收集销售线索、推广小游戏、收集销售线索'] },
      { title: '竞价购买：', describe: ['支持提前预定未来 1-28 天内流量，按 CPM 扣费，优先保量'] },
    ]
  },
  {
    id: '3', title: '充值页广告', url: require('../assets/avdertF.png'),
    tips: '充值界面：根据推广目标展示不同，例如活动/应用/商品推广',
    txtList: [
      { title: '广告位置', describe: ['广告位在公众号/小程序页面内，由流量主决定实际展示位置，流量场景丰富、多样'] },
      { title: '素材规格', describe: ['图片尺寸：960 × 334 px，不超过100 KB', '图片名片式 banner 尺寸：640 × 316 px ，不超过100 KB', '格式：支持 jpg、jpeg、png、bmp'] },
      { title: 'Banner广告支持多种推广目标', describe: ['充值界面 广告支持多种推广目标：推广品牌活动、推广商品、推广应用、收集销售线索、推广小游戏、收集销售线索'] },
      { title: '竞价购买：', describe: ['支持提前预定未来 1-28 天内流量，按 CPM 扣费，优先保量'] },
    ]
  },
];

const tweetList = [
  {
    id: '4', title: '福利中心广告', url: require('../assets/avdertE.png'),
    tips: '弹窗：根据推广目标展示不同，例如活动/应用/商品推广',
    txtList: [
      { title: '广告位置', describe: ['福利中心：任务打卡活动旨在鼓励参与者在一定时间内完成指定的日常任务,并提供奖励以激发参与者的积极参与和完成任务的意愿'] },
      { title: '任务内容形式', describe: ['任务内容形式：激励视频、每日浏览网页、注册激活第三方小程序、导粉等等内容'] },
    ]
  },
  {
    id: '5', title: '充电消息广告', url: require('../assets/avdertD.png'),
    tips: '充电服务提示：根据推广目标展示不同，例如活动/应用/商品推广',
    txtList: [
      { title: '广告位置', describe: ['广告位在公众号/小程序页面内，由流量主决定实际展示位置，流量场景丰富、多样'] },
      { title: '素材规格', describe: ['图片式 尺寸：800*800 px，不超过100 KB', '图片名片式 尺寸：800*800 px ，不超过100 KB', '格式：支持 jpg、jpeg、png、bmp'] },
      { title: '广告支持多种推广目标', describe: ['充值界面 广告支持多种推广目标：推广品牌活动、推广商品、推广应用、收集销售线索、推广小游戏、收集销售线索'] },
      { title: '竞价购买：', describe: ['支持提前预定未来 1-28 天内流量，按 CPM 扣费，优先保量'] },
    ]
  },
  {
    id: '6', title: '微信推文广告', url: require('../assets/avdertC.png'),
    tips: '微信图文推送：根据推广目标不同，例如活动/应用/商品推广',
    txtList: [
      { title: '广告位置', describe: ['广告位在公众号/小程序页面内，由流量主决定实际展示位置，流量场景丰富、多样'] },
      { title: '素材规格', describe: ['图片尺寸：960 × 334 px，不超过100 KB', '图片名片式 banner 尺寸：640 × 316 px ，不超过100 KB', '格式：支持 jpg、jpeg、png、bmp'] },
      { title: '广告支持多种推广目标', describe: ['微信图文推送广告支持多种推广目标：推广品牌活动、推广商品、推广应用、收集销售线索、推广小游戏、收集销售线索'] },
      { title: '竞价购买：', describe: ['按一次性付费、按阅读量付费'] },
    ]
  },
];

const channelList = [
  {
    id: '7', title: '微信跳转广告', url: require('../assets/homeitemK.png'),
    tips: '充电开启直跳：根据推广目标展示不同，例如活动/应用/商品推广',
    txtList: [
      { title: '广告位置', describe: ['广告位在公众号页面内，由流量主决定实际展示位置，流量场景丰富、多样'] },
      { title: '素材规格', describe: ['图片式 尺寸：900*300 px，不超过100 KB', '图片名片式 尺寸：900*300 px ，不超过100 KB', '格式：支持 jpg、jpeg、png、bmp'] },
      { title: '广告支持多种推广目标', describe: ['充电开启直跳 广告支持多种推广目标：推广品牌活动、推广商品、推广应用、收集销售线索、推广小游戏、收集销售线索'] },
      { title: '竞价购买：', describe: ['支持提前预定未来 1-28 天内流量，按 CPM 扣费，优先保量（15-45元）'] },
    ]
  },
  {
    id: '8', title: '小程序跳转广告', url: require('../assets/avdertB.png'),
    tips: '小程序半屏：根据推广目标展示不同，例如活动/应用/商品推广',
    txtList: [
      { title: '广告位置', describe: ['广告位在公众号/小程序页面内，由流量主决定实际展示位置，流量场景丰富、多样'] },
      { title: '素材规格', describe: ['图片式 尺寸：800*800 px，不超过100 KB', '图片名片式 尺寸：800*800 px ，不超过100 KB', '格式：支持 jpg、jpeg、png、bmp'] },
      { title: '广告支持多种推广目标', describe: ['小程序半屏广告支持多种推广目标：推广品牌活动、推广商品、推广应用、收集销售线索、推广小游戏、收集销售线索'] },
      { title: '竞价购买：', describe: ['支持提前预定未来 1-28 天内流量，按 CPM 扣费，优先保量'] },
    ]
  },
  {
    id: '9', title: '支付宝跳转广告', url: require('../assets/avdertA.png'),
    tips: '支付宝直跳：根据推广目标展示不同，例如活动/应用/商品推广',
    txtList: [
      { title: '广告位置', describe: ['广告位在公众号/小程序页面内，由流量主决定实际展示位置，流量场景丰富、多样'] },
      { title: '素材规格', describe: ['图片式 尺寸：900*300 px，不超过100 KB', '图片名片式 尺寸：900*300 px ，不超过100 KB', '格式：支持 jpg、jpeg、png、bmp'] },
      { title: '广告支持多种推广目标', describe: ['支付宝直跳 广告支持多种推广目标：推广品牌活动、推广商品、推广应用、收集销售线索、推广小游戏、收集销售线索'] },
      { title: '竞价购买：', describe: ['支持提前预定未来 1-28 天内流量，按 CPM 扣费，优先保量'] },
    ]
  },
];

// 导出变量或函数（如果需要在其他文件中使用）
export { advertList, tweetList, channelList };
