<template>
  <div class="comm-box">
    <div style="width: 30%;margin-right: 2%;">
      <img style="width: 100%;margin-right: 2%;" alt="Vue logo" :src="data.url">
    </div>

    <div class="comm-data">
      <div class="comm-data-title">{{ data.title }}</div>
      <div class="comm-data-tips">{{ data.tips }}</div>
      <div class="comm-data-fa" v-for="(fat, index) in data.txtList" :key="index + fat.title">
        <div class="comm-data-son-title">{{ fat.title }}</div>
        <div class="comm-data-son-describe comm-data-tips" v-for="(son, index2) in fat.describe"
          :key="index + '-' + index2">{{ son }}</div>
      </div>
    </div>
    <div class="comm-box-buttom" @click="gotoDetail()">查看</div>
  </div>
</template>
        
<script>
export default {
  name: 'AdvertBox',
  props: {
    data: {
      required: true
    }
  },
  methods: {
    goNew(val) {
      if (this.$route.path !== val) {
        this.$router.push(val);
      }
    },
    gotoDetail() {
      const token = localStorage.getItem('token'); // 从本地存储中获取 token
      if (!token) {
        this.$confirm(
          '暂未登录，请先前往登录',
          "系统提示",
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: "warning",
            customClass: 'my-confirm'  //使提示文字颜色变红
          }
        )
          .then(() => {
            // 用户点击了确定按钮
            this.goNew('/login')
          })
          .catch(() => {
            // 用户点击了取消按钮
            console.log('取消')
          })
      } else {
        // 跳转到命名路由
        this.$router.push({ path: '/detail', query: { title: this.data.title } });
      }
    }
  }
}
</script>
      
<style scoped>
.comm-box {
  display: flex;
  /* 垂直居中 */
  /* justify-content: center; */

  /* align-items: center; */
  /* 垂直居中 */
  /* 水平居中 */
}

.comm-data {
  width: 63%;
  flex-direction: column;
  align-items: flex-start;
}

.comm-box-buttom {
  width: 7%;
  min-width: 60px;
  height: 32px;
  margin-top: calc((415px - 32px) / 2);
  /* 使用 calc() 函数计算 margin-top 的值 */
  line-height: 32px;
  border-radius: 16px;
  text-align: center;
  background: #83B24C;
  cursor: pointer;
  font-size: 14px;
  color: #FFFFFF;
  display: inline-block;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2%;
}

.comm-data-title {
  font-size: 22px;
  font-weight: 500;
  color: #2A2A2A;
}

.comm-data-fa {
  margin: 25px 0;
}

.comm-data-tips {
  font-size: 14px;
  color: #9E9E9E;
  margin: 5px 0;
}

.comm-data-son-title {
  font-size: 18px;
  color: #ECB332;

}
</style>
        