<template>
  <div class="charge-main">
    <div v-for="(item, index) in advList" :key="index" style="margin-top: 10px;">
      <AdvertBox :data="item" style="width: 98%;margin: 0 auto;">
      </AdvertBox>
      <el-divider style="width: 90%;" v-if="advList && (index + 1 < advList.length)"></el-divider>
    </div>

  </div>
</template>
  
<script>
import AdvertBox from '../components/AdvertBox.vue'
import { advertList } from '../utils/content.js';
import '../utils/charge.css'; // 引入样式文件

export default {
  name: 'ChargePage',
  components: {
    AdvertBox
  },
  data() {
    return {
      advList: advertList
    }
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  },
}
</script>
  